.MainDiv {
    position: relative;
    justify-content: center;
    overflow: auto;
    min-width: 600px;
}

.ButtonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: fit-content; 
    max-width: 100%; 
    border-radius: 2px;
    border: 2px solid #2b4252;
    border-left: none;
    border-right: none;
    border-top: none;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 20px;
    /* max-width: max-content; */
    /* width: var(--max-width); */
}

/* Media query for smaller screens */
@media (max-width: 1200px) {
    .ButtonRow {
        flex-direction: column;
    }
}



.SelectButton {
    width: 200px;
    height: 200px;
    border-width: 5px;
    border-radius: 100px;
    border-color: transparent;
    margin-left: 5px;
    background-color: #2b4252;
    font-size: 16px;
    color: white;
}

@media (max-width: 1500px) {
    .SelectButton 
        {
            width: 150px;
            height: 150px;
     }
}

@media (max-width: 1200px) {
    .SelectButton 
        {
            width: 200px;
            height: 200px;
     }
}


.SelectButton:hover {
    background-color: #91a2ae;
    font-size: 16px;
}

.SelectButton:focus {
    background-color: #91a2ae;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.SelectButtonPressed {
    width: 200px;
    height: 200px;
    border-width: 5px;
    border-radius: 100px;
    border-color: transparent;
    margin-left: 5px;
    background-color: #99ca3f;
    font-size: 16px;
    color: white;
}

@media (max-width: 1500px) {
     .SelectButtonPressed
     {
        width: 150px;
        height: 150px;
     }
}

@media (max-width: 1200px) {
    .SelectButtonPressed
    {
       width: 200px;
       height: 200px;
    }
}


.SelectButtonPressed:hover {
    background-color: #bbeb63;
    font-size: 16px;
}

.SelectButtonPressed:focus {
    background-color: #c5f073;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.Arrow {
    width: 80px;
    height: 80px;
}


@media (max-width: 1500px) {
    .Arrow {
        width: 60px;
        height: 60px;
    }
}

@media (max-width: 1200px) {
    .Arrow {
        transform: rotate(90deg);
        margin-bottom: 5px;
        width: 80px;
        height: 80px;
    }
}

/* .LoginButtonDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    margin-top: 25px;
    margin-right: 12px;
} */

.LoginButtonDiv {
    display: flex;
    flex-direction: row-reverse; /* Elements start from the right */
    align-items: center; /* Align items vertically center */
    justify-content: flex-start; /* Items start from the right and move to the left */
    margin-top: 25px;
    margin-right: 15px;
}

.LoginButton {
    position: relative;
    float: right;
    /* width: 10px; */
    height: 40px;
    width: 90px;
    border-width: 3px;
    border-color: white;
    border-radius: 10px;
    margin-top: -110px;
    margin-right: 10px;
    padding-bottom: 5px;
    background-color: #2b4252;
    font-size: 16px;
    color: white;
}

.LoginButton:hover {
    background-color: #91a2ae;
}

.UserName {
    position: relative;
    float: left;
    width: 90px;
    height: 40px;
    border-width: 3px;
    border-color: white;
    border-radius: 10px;
    margin-top: -90px;
    margin-right: 10px;
    padding-bottom: 5px;
    font-size: 16px;
    color: white;
    max-width: max-content;
    direction: rtl;
}


.TextDisplay {
    flex: 1; /* Take up the remaining space */
    max-width: 70%; /* Limit the width to avoid overlap */
    overflow-wrap: break-word; /* Break long words to prevent overflow */
    margin-right: 10px; /* Add some space to the right to avoid touching other elements */
}
.DownloadButtonDiv {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.DownloadButton {
    width: 200px;
    height: 50px;
    border-width: 5px;
    border-radius: 10px;
    margin-top: 70px;
    align-items: center;
    background-color: #2b4252;
    font-size: 14px;
    color: white;
}

Link {
    color: white;
}

.LineBreak {
    border: none; /* Remove default border */
    border-top: 2px solid #2b4252; /* Specify your desired color */
    margin-top: 20px;
    justify-content: center;
    /* max-width: 85%; */
}

.Description {
    background-color: #faf9f9;
    border: 1px solid #ccc;
    padding: 20px;
    font-size: 15px;
    color: #333;
    text-align: justify;
    line-height: 1.6;
    max-width: 80%;
    margin: 20px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 100px;
    font-weight: bold;
}

@media (max-width: 1500px) {
    .Description {
        max-width: 65%;
    }
}

@media (min-width: 1800px) {
    .Description {
        max-width: 70%;
    }
}
