.MainDiv {
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-top: 50px;
}

.SecondDiv {
    display: flex;
    flex-direction: row;
}

.InputFile {
    margin-top: 2px;
    margin-left: 10px;
}

.UploadButton {
    width: 110px;
    height: 40px;
    border-width: 5px;
    border-color: white;
    border-radius: 10px;
    background-color: #2b4252;
    font-size: 12px;
    color: white;
    margin-top: -5px;
    margin-left: 10px;
}

.UploadButton:hover {
    background-color: #91a2ae;
}

.FilesDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RemoveFileButton {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 8px;
    text-align: left;
    margin-top: 5px;
    padding:0;
    padding-left: 2px;
}

.File {
    margin-left: 40px;
    margin-top: -16px;
}