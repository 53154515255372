.ErrorLogo{
    color: red;
    height: 20px;
    width: 20px;
}

.ErrorMessage {
    color: red;
    margin: 5px;
    font-size: 15px;
}