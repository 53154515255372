.MainBackground {
    background-color:#eaebef;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.RegistrationForm{
    /* text-align: center;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    background-color: white; */
    text-align: left;
    display: flex; 
    justify-content: left; 
    align-items: left; 
    flex-direction: column;
    background-color:#eaebef;
}

.Header{
    padding-left: 2.5px;
    color: #8e8a8af7;
}

.Logo{
    margin-top: 0px;
    align-items: left;
    height: 90px;
    width: 90px;
    /* height: 90px;
    width: 3200px; */
}


hr {
    background-color: rgb(25, 24, 24);
    border-style: none;
    border-top-style: dotted;
    height: 0px;
    width: 100%;
    margin-bottom: 50px;
  }

.Border {
    /* text-align: center; */
    /* display: flex;  */
    /* justify-content: center;  */
    /* align-items: center;  */
    /* flex-direction: column; */
    width: 50%;
    background-color: #ffffff;
    margin-top: 60px;
    margin-left: 25%;
    padding: 30px;
    padding-top: 10px;
    border: 2px solid #ccc;
    border-radius: 10px;
    border: 2px solid #ccc;
    /* margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px; */

}

.Button {
    margin-top: 20px;
    width: 110px;
    height: 40px;
    background-color: #2b4252; ; 
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.Button:hover {
    background-color: #82909b;
    color: white;
  }

  .ErrorDiv{
    margin-left: 25%;
  }