.blurb {
    background-color: #f0f0f0; /* Background color */
    border: 1px solid #ccc; /* Border */
    padding: 10px; /* Padding around the blurb */
    font-size: 16px; /* Font size */
    color: #333; /* Text color */
    text-align: justify; /* Justify text */
    line-height: 1.6; /* Line height for readability */
    max-width: max-content; /* Maximum width of the blurb */
    margin: 20px auto; /* Center horizontally and add some vertical margin */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
    border-radius: 5px; /* Rounded corners */
    margin-top: 50px;
    /* clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%); */
  }
  